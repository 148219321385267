import styled from "@emotion/styled";
const Styles = styled.div`
  .ant-row {
    display: flex;
  }
  .ant-form-item .ant-form-item-label > label {
    min-width: 171px;
  }
`;


export default Styles;
