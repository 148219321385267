import { ModalDeleteStyle } from "./styled";
import { Button, ModalProps } from "antd";
import { CircleWarning, XMask } from "assets/icons";

const ModalConfirmDelete = (props: ModalProps) => {
  return (
    <ModalDeleteStyle
      title={
        <div className="flex justify-center mt-4 mb-7">
          <CircleWarning width={79} height={79} />
        </div>
      }
      width={441}
      closeIcon={<XMask />}
      footer={
        <div className="flex justify-center gap-[10px] mt-[33px] mb-[6px]">
          <Button
            className="w-[147px] h-9 text-[#3A3B3C] text-xl font-medium !m-0"
            onClick={props.onCancel}
            {...props.cancelButtonProps}
          >
            いいえ
          </Button>
          <Button
            className="w-[147px] h-9 bg-[#cc3e3f] text-white text-xl font-medium !m-0"
            onClick={props.onOk}
            {...props.okButtonProps}
          >
            はい
          </Button>
        </div>
      }
      {...props}
    >
      <p className="text-[#cc3e3f] text-xl font-medium text-center">
        削除してよろしいですか。
      </p>
    </ModalDeleteStyle>
  );
};

export default ModalConfirmDelete;
