import React, { useEffect, useState } from "react";
import { DatePicker, Form, Input } from "antd";
import axiosClient from "api/axiosClient";
import useService from "hook/useService";
import { toast } from "react-toastify";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import dayjs from "dayjs";
import Styles from "./styled";
import { ButtonOutline, ButtonSolid } from "component/button";
import MESSAGES from "constant/messages";
import ROUTES from "constant/routes";
import { DATE_FORMAT_2, JP_DATEPICKER_FORMAT } from "constant/date";

const RecipientCertification = () => {
  const { id } = useParams();
  const [form] = Form.useForm();
  const [isEdit, setIsEdit] = useState(!Boolean(id));
  const service = useService();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const recipientId = searchParams.get("recipient");

  const handleSubmit = async (formData: any) => {
    formData.birth_date = formData.birth_date?.format(DATE_FORMAT_2);
    formData.period_contract = formData.period_contract?.format(DATE_FORMAT_2);
    try {
      const res = await axiosClient({
        method: id ? "put" : "post",
        url: `${service.RECIPIENTS}${recipientId ? `/${recipientId}` : ""}`,
        data: formData,
      });
      toast.success('success')
      //toast.success(res.data.success);
      setIsEdit(false);
      !id && navigate(`/${ROUTES.ADMIN}/${ROUTES.CUSTOMER}`);
    } catch (error) {
      toast.error("Error");
    }
  };

  const fetchData = async () => {
    try {
      const { data } = await axiosClient({
        method: "get",
        url: `${service.RECIPIENTS}/${recipientId}`,
      });
      form.setFieldsValue(data);
      console.log(data);
      data.contract_date &&
        form.setFieldValue("contract_date", dayjs(data.contract_date));
      data.applicable_start_date &&
        form.setFieldValue(
          "applicable_start_date",
          dayjs(data.applicable_start_date)
        );
      data.service_end_date &&
        form.setFieldValue("service_end_date", dayjs(data.service_end_date));
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (recipientId) fetchData();
  }, [recipientId]);

  return (
    <Styles className=" relative mb-20">
      <div className="font-bold flex items-center text-xl border-b border-[#d9d9d9] border-solid pb-4 mb-5 mt-10 ">
        <div className="mr-2 h-[5px] w-[5px] bg-black rounded-[100%]"></div>
        <span>受給者証情報</span>
      </div>
      <Form
        disabled={!isEdit && !!Boolean(id)}
        form={form}
        onFinish={handleSubmit}
        autoComplete="off"
        scrollToFirstError={{ block: "center" }}
      >
        <div className="max-w-[700px] w-full">
          <Form.Item
            label="事業者記入欄番号"
            name="business_code"
            rules={[
              {
                required: true,
                message: MESSAGES.requireField,
              },
            ]}
          >
            <Input autoComplete="off" placeholder="入力してください" />
          </Form.Item>

          <Form.Item
            label="受給者番号"
            name="recipient_code"
            rules={[
              {
                required: true,
                message: MESSAGES.requireField,
              },
            ]}
          >
            <Input autoComplete="off" placeholder="入力してください" />
          </Form.Item>

          <Form.Item
            label="サービス内容"
            name="service_content"
            rules={[
              {
                required: true,
                message: MESSAGES.requireField,
              },
            ]}
          >
            <Input autoComplete="off" placeholder="入力してください" />
          </Form.Item>
          <Form.Item
            label="契約支給量"
            name="contract_allocation_amount"
            rules={[
              {
                required: true,
                message: MESSAGES.requireField,
              },
            ]}
          >
            <Input autoComplete="off" placeholder="入力してください" />
          </Form.Item>

          <Form.Item
            label="契約日"
            name="contract_date"
            rules={[
              {
                required: true,
                message: MESSAGES.requireField,
              },
            ]}
          >
            <DatePicker
              //disabledDate={(date) => date.isAfter(Date.now())}
              format={JP_DATEPICKER_FORMAT}
              className="w-full"
              placeholder="選択してください"
              allowClear={false}
            />
          </Form.Item>

          <Form.Item
            className="gap-4"
            label={
              <div className="text-left">
                当該契約支給量による <br />
                サービス提供終了日
              </div>
            }
            name="service_end_date"
            rules={[
              {
                required: true,
                message: MESSAGES.requireField,
              },
            ]}
          >
            <DatePicker
              // disabledDate={(date) => date.isAfter(Date.now())}
              format={JP_DATEPICKER_FORMAT}
              className="w-full"
              placeholder="選択してください"
              allowClear={false}
            />
          </Form.Item>

          <Form.Item
            label="適用開始年月日"
            name="applicable_start_date"
            rules={[
              {
                required: true,
                message: MESSAGES.requireField,
              },
            ]}
          >
            <DatePicker
              //disabledDate={(date) => date.isAfter(Date.now())}
              format={JP_DATEPICKER_FORMAT}
              className="w-full"
              placeholder="選択してください"
              allowClear={false}
            />
          </Form.Item>

          <div className="flex ">
            <Form.Item
              label="利用者負担割合(原則)"
              name="user_burden_percentage"
              rules={[
                {
                  required: true,
                  message: MESSAGES.requireField,
                },
              ]}
            >
              <Input
                autoComplete="off"
                placeholder="入力してください"
                className="w-[158px]"
              />
            </Form.Item>
            <span className="ml-2 mt-1">割</span>
          </div>
          <div className="flex ">
            <Form.Item
              label="負担上限月額"
              name="monthly_cost_limit"
              rules={[
                {
                  required: true,
                  message: MESSAGES.requireField,
                },
              ]}
            >
              <Input
                autoComplete="off"
                placeholder="入力してください"
                className="w-[158px]"
              />
            </Form.Item>
            <span className="ml-2 mt-1">円</span>
          </div>
        </div>
      </Form>
      <div className="h-20 w-[calc(100%+40px)] bg-[#eeeeee] absolute -bottom-20 -right-5"></div>

      <div className="flex gap-x-[8px] fixed bottom-0 bg-white w-full right-0 p-4 justify-end ">
        <ButtonOutline
          onClick={() => {
            setIsEdit(false);
            navigate(-1);
          }}
          className="!h-[35px] !px-[16px] !text-[14px] !leading-[24px]"
        >
          キャンセル
        </ButtonOutline>
        <ButtonSolid
          onClick={() => (isEdit ? form.submit() : setIsEdit(true))}
          className="!h-[35px] !px-[54px] !text-[14px] !leading-[24px]"
        >
          {!!Boolean(id) && !isEdit ? "編集" : "保存"}
        </ButtonSolid>
      </div>
    </Styles>
  );
};

export default RecipientCertification;
