import { Stack } from "@chakra-ui/layout";
import { Radio, RadioGroup } from "@chakra-ui/radio";
import {
  FlexRow,
  StyleGrid,
  StyleGridItem,
  StyleTitle,
} from "component/StyledComponent";
import { ButtonSolid } from "component/button";
import {
  useCallback,
  useEffect,
  useState,
  useRef,
  Dispatch,
  SetStateAction,
  useMemo,
} from "react";
import RadioForm, { styleRadioProps } from "./RadioForm";
import CheckboxForm from "./CheckboxForm";
import { Textarea } from "@chakra-ui/textarea";
import styled from "@emotion/styled";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import "./dailyform.scss";
import { DatePickerProps, Form, Input, TimePicker } from "antd";
import locale from "antd/es/date-picker/locale/ja_JP";
import "moment/locale/ja";
import "moment-timezone";
import "dayjs/locale/ja";
import dayjs, { ConfigType } from "dayjs";
import { Text } from "@chakra-ui/react";
import moment from "moment-timezone";
import { toast } from "react-toastify";
import { getCookie, TIMEJAPAN, FORMAT_FOR_SET_TIMEZONE } from "utils";
import nursingCareRecordApi from "api/admin/nursingCareRecord";
import queryString from "query-string";
import { useSelector } from "react-redux";
import { RootState } from "store";
import FormSelect from "./FormSelect";
import { useForm } from "antd/es/form/Form";
import ROUTES from "constant/routes";
import { DATE_FORMAT_4, TIME_FORMAT, formatDate } from "constant/date";
import { twMerge } from "tailwind-merge";
import { END_OF_DATE, START_OF_DATE } from "page/admin/NursingCareRecordDetail";
import { ROLE } from "constant";
import { isObject } from "lodash"

interface DailyFormProps {
  footerClassName?: string;
  wrapperClassName?: string;
  showServiceName?: boolean;
  canEdit?: boolean;
  isHistory?: boolean;
  isStaff?: boolean;
  setCanEdit?: Dispatch<SetStateAction<boolean>>;
}

export const getTypeCarePlanParam = (type: string) => {
  switch (type) {
    case "NursingCarePlan":
      return "normal";
    case "DisabilityCarePlan":
      return "disability";
    default:
      return "normal";
  }
};

const eatingData = [
  {
    value: "なし",
    content: "なし",
  },
  {
    value: "全部",
    content: "全部",
  },
  {
    value: "一部",
    content: "一部",
  },
];

const dataNum = Array.from({ length: 11 })?.map((_, index) => ({
  value: `${index}`,
  content: `${index}`,
}));

const dataNumCC = Array.from({ length: 11 })?.map((_, index) => ({
  value: `${index * 50}`,
  content: `${index * 50}`,
}));

const bodyBathData = [
  {
    value: "なし",
    content: "なし",
  },
  {
    value: "入浴",
    content: "入浴 ",
  },
  {
    value: "シャワー浴",
    content: "シャワー浴",
  },
];

function usePreviousValue(value: any) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

const DailyForm = (props: DailyFormProps) => {
  const {
    footerClassName,
    wrapperClassName,
    showServiceName = true,
    canEdit,
    setCanEdit,
    isHistory,
    isStaff,
  } = props;
  dayjs.locale('ja')
  const idToken = getCookie("access_token");
  const location = useLocation();
  const queryObj: any = queryString.parse(location.search);
  const user = useSelector((state: RootState) => state.user);

  const [form] = useForm();
  const { id } = useParams();
  const [defaultValue, setDefaultValue] = useState({
    date: "",
    serContent: "",
    serDetail: "",
    staff: !isHistory && isStaff ? user?.id : queryObj.staff_id || null,
    eating: "なし",
    cleaning: "なし",
    bodyBath: "なし",
    report: "",
    supportActDetail: "",
    urinate: "0",
    defecation: "0",
    hydration: "0",
    updated_time: "",
    temperature: "",
    bloodPressure: {
      systolic: "",
      diastolic: "",
    },
  });

  const [checkValue, setCheckValue] = useState({
    enviArr: null,
    consultAssist: null,
    record: null,
    toilet: null,
    diaper: null,
    pad: null,
    urinal: null,
    maintain: null,
    hair: null,
    bodyWash: null,
    wash: null,
    oral: null,
    dressing: null,
    position: null,
    mobility: null,
    watch: null,
  });

  const [timeStart, setTimeStart]: any = useState(
    new Date(new Date().setHours(9, 0, 0, 0))
  );
  const [timeStartReal, setTimeStartReal]: any = useState(
    new Date(new Date().setHours(9, 0, 0, 0))
  );

  const [timeEnd, setTimeEnd]: any = useState(
    new Date(new Date().setHours(9, 30, 0, 0))
  );
  const [timeEndReal, setTimeEndReal]: any = useState(
    new Date(new Date().setHours(9, 28, 0, 0))
  );
  const [dayDisplay, setDayDisplay]: any = useState(new Date());
  const [dayShow, setDayShow] = useState("");
  const [division, setDivision] = useState(queryObj?.division || "");
  const [physicCare, setPhysicCare] = useState("body");
  const [complex, setComplex] = useState("");
  const [sweat, setSweat] = useState("");
  const [serviceDetail, setServiceDetail] = useState("");
  const navigate = useNavigate();
  const [errorVal, setErrorVal] = useState({
    errComplex: "",
    errSweat: "",
  });
  const [serContentData, setSerContentData] = useState([]);
  const [serviceDetailData, setserviceDetailData] = useState([]);
  const [staffInCharge, setStaffInCharge] = useState([]);
  const [errorToScroll, setErrorToScroll] = useState("");
  const [isUpdatedTime, setIsUpdatedTime] = useState<boolean>(false);

  const isShowEditButton = useMemo(() => {
    if (!!queryObj.staff_id && queryObj.staff_id !== String(user?.id)) {
      return false;
    }

    return true;
  }, [queryObj, user]);

  const canEditStaff = useMemo(() => {
    if (isStaff) {
      return true;
    }

    return !canEdit;
  }, [canEdit, isStaff]);

  const onChangeValue = (evt: any, field?: string, value?: any) => {
    let _name = "";
    let _value = "";

    if (field && value) {
      _name = field;
      _value = value;
    } else {
      _name = evt.target.name;
      _value = evt.target.value;
    }

    setDefaultValue((prev) => ({ ...prev, [_name]: _value }));
  };

  const onChangeCheckValue = (evt: any) => {
    const name = evt.target.name;
    setCheckValue((prev) => ({ ...prev, [name]: evt.target.checked }));
  };

  const onChangeDivision = (evt: any) => {
    setDivision(evt);
    if (evt === "normal") {
      setPhysicCare("body");
    } else if (evt === "disability") {
      setPhysicCare("body");
    }
  };

  const getStaffActive = async () => {
    try {
      const param = {
        "status[]": ["active"],
        per: "10000",
      };
      const res = await nursingCareRecordApi.getStaffActive(
        idToken,
        param,
        user?.role
      );
      if (res) {
        const newRes = res.nursing_staffs.map((item: any) => ({
          value: item.id.toString(),
          content: `${item.family_name}`,
        }));
        setStaffInCharge(newRes);

        if (defaultValue.staff) {
          form.setFieldValue("staff", defaultValue.staff?.toString());
        }
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const getSerDetail = useCallback(async () => {
    try {
      const param = {
        per: "10000",
      };
      const res = await nursingCareRecordApi.getServiceType(
        idToken,
        param,
        user?.role
      );
      if (res) {
        if (res.serialized_service_types) {
          const newRes = res.serialized_service_types.map((item: any) => ({
            value: item.id?.toString(),
            content: item.detail,
          }));
          setserviceDetailData(newRes);
        } else {
          const newRes = res.data.map((item: any) => ({
            value: item.id?.toString(),
            content: item.detail,
          }));
          setserviceDetailData(newRes);
        }
      }
    } catch (error) {
      console.log("error", error);
    }
  }, []);

  const covertStringToOjectPressure = (pressure: string) => {
    const initData = { ...defaultValue.bloodPressure };
    if (!pressure) return initData;
    const pressureArray = pressure.split("/");
    initData.systolic = pressureArray[0] || "";
    initData.diastolic = pressureArray[1] || "";
    return initData;
  };

  const getNursingCare = async () => {
    try {
      const res = await nursingCareRecordApi.getDetailNursingCare(
        idToken,
        queryObj,
        user?.role
      );
      if (res) {
        setDefaultValue((prev) => ({
          ...prev,
          serContent: res.service_id,
          defecation: res.defecation || "0",
          urinate: res.urination || "0",
          cleaning: res.cleaning,
          eating: res.eating_assistance,
          bodyBath: res.full_body_bath,
          hydration: res.hydration,
          report: res.note,
          updated_time: res.updated_time,
          temperature: res.temperature,
          bloodPressure: covertStringToOjectPressure(res.blood_pressure),
          ...(res.nurse_id && { staff: res.nurse_id }),
        }));

        res?.nurse_id && form.setFieldValue("staff", res?.nurse_id?.toString());
        setCheckValue({
          ...checkValue,
          enviArr: res.environmental_arrangement,
          consultAssist: res.consultation_assistance,
          record: res.record,
          toilet: res.toilet_assistance,
          diaper: res.diaper_check,
          pad: res.pad_confirmation,
          urinal: res.urinal_cleaning,
          maintain: res.maintain_posture,
          hair: res.washing_hair,
          bodyWash: res.full_body_bath_procedure,
          wash: res.washbasin,
          oral: res.oral_care,
          dressing: res.dressing_assistance,
          position: res.position_exchange,
          mobility: res.transfer_assistance,
          watch: res.watch_over,
        });
        setDivision(res.division);
        setComplex(res.complexion);
        setDayShow(res?.schedule_date?.time_range);
        !queryObj.end_time &&
          setTimeEndReal(moment.tz(res.end_time, TIME_FORMAT, TIMEJAPAN).format(FORMAT_FOR_SET_TIMEZONE));

        !queryObj.start_time &&
          setTimeStartReal(
            moment.tz(res.start_time, TIME_FORMAT, TIMEJAPAN).format(FORMAT_FOR_SET_TIMEZONE)
          );
        setPhysicCare(res?.physical_care || "body");
        setServiceDetail(res.service_type_id?.toString());
        setSweat(res.sweating);
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  const getSerContent = async (isUpdate = false) => {
    const param = {
      patient_type: division,
      per: "10000",
    };
    try {
      const res = await nursingCareRecordApi.getPatientService(
        idToken,
        param,
        user?.role
      );
      if (res) {
        if (res.serialized_services) {
          const newRes = res.serialized_services.map((item: any) => ({
            value: item.id,
            content: item.service_name,
          }));
          setSerContentData(newRes);
          // if (isUpdate) {
          //   setDefaultValue((prev) => ({
          //     ...prev,
          //     serContent: newRes[0].value,
          //   }));
          // }
        } else {
          const newRes = res.data.map((item: any) => ({
            value: item.id,
            content: item.service_name,
          }));
          setSerContentData(newRes);
          // if (isUpdate) {
          //   setDefaultValue((prev) => ({
          //     ...prev,
          //     serContent: newRes[0].value,
          //   }));
          // }
        }
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const getScheduleDetail = async () => {
    try {
      const res = await nursingCareRecordApi.getSchedule(
        idToken,
        queryObj.scheduleable,
        user?.role
      );

      if (res) {
        setServiceDetail(res.service_type.id?.toString());
        setDefaultValue((prev) => ({ ...prev, serContent: res.service.id }));
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleBack = () => {
    if (queryObj.redirectPath) {
      navigate(queryObj.redirectPath);
    } else {
      navigate(`/${ROUTES.ADMIN}/${ROUTES.SHIFT}/${ROUTES.SHIFT_MANAGEMENT}`);
    }
  };

  useEffect(() => {
    getSerDetail();
  }, [getSerDetail]);

  useEffect(() => {
    if (queryObj.type === "edit") {
      getNursingCare();
    } else if (queryObj.type === "create") {
      getScheduleDetail();
    }
    getStaffActive();
  }, []);

  const previousValue = usePreviousValue(division);

  useEffect(() => {
   if (showServiceName) {
    if (previousValue) {
      getSerContent(true);
    } else {
      getSerContent(false);
    }    
   }
  }, [division, showServiceName]);

  const onChangeTimeStart: DatePickerProps["onChange"] = (date: any) => {
    if (date) {
      const originalDate = new Date(date);
      const dateInInputTZ = moment.tz(originalDate, TIMEJAPAN);
      const dateInJST = dateInInputTZ.tz(TIMEJAPAN);
      const formattedDate = dateInJST.format(FORMAT_FOR_SET_TIMEZONE);
      setTimeStartReal(formattedDate);
    } else {
      setTimeStartReal(null);
    }
  };

  const onChangeTimeEnd: DatePickerProps["onChange"] = (date: any) => {
    if (date) {
      const originalDate = new Date(date);
      const dateInInputTZ = moment.tz(originalDate, TIMEJAPAN);
      const dateInJST = dateInInputTZ.tz(TIMEJAPAN);
      const formattedDate = dateInJST.format(FORMAT_FOR_SET_TIMEZONE);
      setTimeEndReal(formattedDate);
    } else {
      setTimeEndReal(null);
    }
  };

  useEffect(() => {
    if (location.search) {
      setDayDisplay(new Date(queryObj.date));
      setTimeStart(
        moment.tz(queryObj.start_time, TIME_FORMAT, TIMEJAPAN).format(FORMAT_FOR_SET_TIMEZONE)
      );
     setTimeEnd(moment.tz(queryObj.end_time, TIME_FORMAT, TIMEJAPAN).format(FORMAT_FOR_SET_TIMEZONE));
      setDivision(getTypeCarePlanParam(queryObj?.division));

      setTimeStartReal(
        moment.tz(queryObj.start_time, TIME_FORMAT, TIMEJAPAN).format(FORMAT_FOR_SET_TIMEZONE)
      );
      setTimeEndReal(
        moment.tz(queryObj.end_time, TIME_FORMAT, TIMEJAPAN).format(FORMAT_FOR_SET_TIMEZONE)
      );
    }
  }, [location?.search, setDefaultValue]);

  const validate = () => {
    let isValidate = false;
    let err: any = {};

    if (!complex) {
      err.errComplex = "選択してください";
      isValidate = true;

      setErrorToScroll((prev) => (!prev ? "errComplex" : prev));
    }

    if (!sweat) {
      err.errSweat = "選択してください";
      isValidate = true;

      setErrorToScroll((prev) => (!prev ? "errSweat" : prev));
    }

    setErrorVal(err);
    return isValidate;
  };

  const getTimeFromString = (dateString: string) => {
    return dateString.split(' ')[4].slice(0, 5);
  }

  const handleSubmit = async () => {
    setErrorToScroll("");

    if (validate()) {
      toast.error("選択してください");
      return;
    } else {
      setErrorVal({
        errComplex: "",
        errSweat: "",
      });
      let end_time = timeEndReal
      ? getTimeFromString(timeEndReal)
      : getTimeFromString(timeEnd);

    const start_time = timeStartReal
      ? getTimeFromString(timeStartReal)
      : getTimeFromString(timeStart);
    const start_time_format = getTimeFromString(timeStartReal);

    const end_time_format = getTimeFromString(timeEndReal);
    end_time = end_time === START_OF_DATE ? END_OF_DATE : end_time;
    const body: any = {
        patient_id: parseInt(id as string),
        schedule_date_id: parseInt(queryObj.schedule_date_id),
        full_body_bath: defaultValue.bodyBath,
        urination: defaultValue.urinate,
        defecation: defaultValue.defecation,
        hydration: defaultValue.hydration,
        division: division,
        service_id: defaultValue.serContent,
        service_type_id: Number(serviceDetail),
        nurse_id: defaultValue.staff,
        eating_assistance: defaultValue.eating,
        cleaning: defaultValue.cleaning,
        report: defaultValue.report || null,
        environmental_arrangement: checkValue.enviArr || null,
        consultation_assistance: checkValue.consultAssist || null,
        record: checkValue.record || null,
        toilet_assistance: checkValue.toilet || null,
        diaper_check: checkValue.diaper || null,
        pad_confirmation: checkValue.pad || null,
        urinal_cleaning: checkValue.urinal || null,
        maintain_posture: checkValue.maintain || null,
        washing_hair: checkValue.hair || null,
        full_body_bath_procedure: checkValue.bodyWash || null,
        washbasin: checkValue.wash || null,
        oral_care: checkValue.oral || null,
        dressing_assistance: checkValue.dressing || null,
        position_exchange: checkValue.position || null,
        transfer_assistance: checkValue.mobility || null,
        watch_over: checkValue.watch || null,
        physical_care: physicCare,
        complexion: complex,
        sweating: sweat,
        note: defaultValue.report,
        temperature: defaultValue.temperature,
        blood_pressure:
          defaultValue.bloodPressure.systolic ||
          defaultValue.bloodPressure.diastolic
            ? `${defaultValue.bloodPressure.systolic}/${defaultValue.bloodPressure.diastolic}`
            : "",
        end_time: end_time,
        start_time,
        ...(user?.role === ROLE.ADMIN && {
          start_time_format,
          end_time_format,
        }),
        ...(isUpdatedTime && { updated_time: defaultValue?.updated_time }),
      };

      if (queryObj.type === "create") {
        try {
          const res = await nursingCareRecordApi.createNursingCare(
            idToken,
            body,
            user?.role
          );
          if (res) {
            toast.success(res?.success);
            handleBack();
          }
        } catch (error: any) {
          toast.error(error.message.messages);
        }
      } else {
        try {
          const res = await nursingCareRecordApi.updateNursingCare(
            idToken,
            body,
            user?.role
          );

          if (res) {
            toast.success(res?.success);
            getNursingCare();
            handleBack();
          }
        } catch (error: any) {
          toast.error(error.message.messages);
        }
      }
    }
  };

  const handelScrollToError = (id?: string) => {
    const _id = id || errorToScroll;

    if (!_id) return;
    const element = document.getElementById(_id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleChangeUpdatedTime = (date: ConfigType) => {
    setDefaultValue({
      ...defaultValue,
      updated_time: dayjs(date).format(DATE_FORMAT_4),
    });
    setIsUpdatedTime(true);
  };

  const formatDisplayTime = ({
    value,
  }: React.InputHTMLAttributes<HTMLInputElement>) => {
    return value === START_OF_DATE ? END_OF_DATE : value;
  };

  const handleChangeBloodPressure = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const name = e.target.name as keyof typeof defaultValue.bloodPressure;
    defaultValue.bloodPressure[name] = e.target.value;
    setDefaultValue({ ...defaultValue });
  };
  useEffect(() => {
    if (errorToScroll) {
      handelScrollToError();
    }
  }, [errorToScroll]);
  
  const convertTimeFormatJPtoEn = (timeString: string) => {
    try {
      if(isObject(timeString)) return new Date()
        const components = timeString.split(' ');
        // Construct ISO 8601 format
        const year = components[3]; 
        const month = components[1]; 
        const day = components[2]; 
        const time = components[4]; 
    
        //ISO 8601 formatted string
        return `${year}-${month}-${day}T${time}${components[5].replace('GMT', '')}`;
    } catch (err) {
      return new Date()
    }

  };

  return (
    <div className={twMerge("", wrapperClassName)}>
      <FlexRow className="flex-wrap" style={{ textAlign: "left" }}>
        <StyleTitle className="w-full ">■概要</StyleTitle>
        <div className="w-full md:w-3/5">
          <StyleGrid templateColumns="repeat(4, 1fr)" gap={6}>
            <StyleGridItem colSpan={1}>
              <TextP>日付</TextP>
            </StyleGridItem>
            <StyleGridItem colSpan={3}>
              <Input
                value={moment(dayDisplay).format("YYYY/MM/DD (ddd)")}
                onChange={() => {}}
                readOnly
                size="large"
                disabled={!canEdit}
              />
            </StyleGridItem>
          </StyleGrid>

          <StyleGrid templateColumns="repeat(4, 1fr)" gap={6}>
            <StyleGridItem colSpan={1}>
              <TextP>対応開始予定時間</TextP>
            </StyleGridItem>
            <StyleGridItem colSpan={3}>
              <StyleTimePicker
                disabled={!canEdit || isStaff}
                inputReadOnly
                format={TIME_FORMAT}
                value={dayjs(convertTimeFormatJPtoEn(timeStartReal)).tz(TIMEJAPAN)}
                showHour
                showMinute
                onChange={onChangeTimeStart}
                locale={locale}
                className="w-full"
                size="large"
                allowClear={false}
                suffixIcon={<></>}
              />
            </StyleGridItem>
          </StyleGrid>

          <StyleGrid templateColumns="repeat(4, 1fr)" gap={6}>
            <StyleGridItem colSpan={1}>
              <TextP>対応終了予定時間</TextP>
            </StyleGridItem>

            <StyleGridItem colSpan={3}>
              <StyleTimePicker
                disabled={!canEdit || isStaff}
                inputReadOnly
                value={dayjs(convertTimeFormatJPtoEn(timeEndReal)).tz(TIMEJAPAN)}
                format={TIME_FORMAT}
                showHour
                showMinute
                onChange={onChangeTimeEnd}
                locale={locale}
                className="w-full"
                size="large"
                allowClear={false}
                suffixIcon={<></>}
                inputRender={(inputProps) => (
                  <input
                    {...inputProps}
                    readOnly
                    value={formatDisplayTime(inputProps)}
                  />
                )}
              />
            </StyleGridItem>
          </StyleGrid>

          <StyleGrid templateColumns="repeat(4, 1fr)" gap={6}>
            <StyleGridItem colSpan={1}>
              <TextP>対応終了実時間</TextP>
            </StyleGridItem>

            <StyleGridItem colSpan={3}>
              <StyleTimePicker
                disabled={!canEdit}
                inputReadOnly
                value={
                  dayjs(defaultValue.updated_time).isValid() &&
                  !!defaultValue.updated_time
                    ? dayjs(defaultValue.updated_time)
                    : null
                }
                format={TIME_FORMAT}
                showHour
                showMinute
                onChange={handleChangeUpdatedTime}
                locale={locale}
                className="w-full"
                size="large"
                allowClear={false}
                suffixIcon={null}
              />
            </StyleGridItem>
          </StyleGrid>

          <StyleGrid templateColumns="repeat(4, 1fr)" gap={6}>
            <StyleGridItem colSpan={1}>
              <TextP>区分</TextP>
            </StyleGridItem>
            <StyleGridItem colSpan={3}>
              <RadioGroup
                colorScheme="white"
                name="division"
                onChange={onChangeDivision}
                value={division}
              >
                <Stack direction="row">
                  <Radio
                    _before={styleRadioProps}
                    isDisabled={true}
                    value="normal"
                    borderColor={`${
                      division === "介護" ? "var(--header-color)" : "#000"
                    }`}
                  >
                    <p>介護</p>
                  </Radio>
                  <Radio
                    _before={styleRadioProps}
                    value="disability"
                    borderColor={`${
                      division === "障害" ? "var(--header-color)" : "#000"
                    }`}
                    isDisabled={true}
                  >
                    <p>障害</p>{" "}
                  </Radio>
                </Stack>
              </RadioGroup>
            </StyleGridItem>
          </StyleGrid>

          {/* Physic Care */}
          {division === "normal" && (
            <StyleGrid templateColumns="repeat(4, 1fr)" gap={6}>
              <StyleGridItem colSpan={1}>
                <TextP>身体介護</TextP>
              </StyleGridItem>
              <StyleGridItem colSpan={3}>
                <RadioGroup
                  colorScheme="white"
                  name="physicCare"
                  onChange={setPhysicCare}
                  value={physicCare}
                >
                  <Stack direction="row">
                    <Radio
                      value="body"
                      _before={styleRadioProps}
                      isDisabled={!canEdit}
                      borderColor="#000"
                    >
                      <p>身体</p>
                    </Radio>
                    <Radio
                      value="life"
                      _before={styleRadioProps}
                      isDisabled={!canEdit}
                      borderColor="#000"
                    >
                      <p>生活</p>
                    </Radio>
                  </Stack>
                </RadioGroup>
              </StyleGridItem>
            </StyleGrid>
          )}

          {/* Comprehensive Support Act for Persons with Disabilities */}
          {division === "disability" && (
            <StyleGrid templateColumns="repeat(4, 1fr)" gap={6}>
              <StyleGridItem colSpan={1}>
                <TextP>障害者総合支援法</TextP>
              </StyleGridItem>
              <StyleGridItem colSpan={3}>
                <RadioGroup
                  colorScheme="white"
                  name="physicCare"
                  onChange={setPhysicCare}
                  value={physicCare}
                >
                  <Stack direction="row">
                    <Radio
                      value="body"
                      _before={styleRadioProps}
                      isDisabled={!canEdit}
                      borderColor="#000"
                    >
                      <p>身体</p>{" "}
                    </Radio>
                    <Radio
                      value="severe_visit"
                      _before={styleRadioProps}
                      isDisabled={!canEdit}
                      borderColor="#000"
                    >
                      <p>重度訪問</p>
                    </Radio>
                  </Stack>
                </RadioGroup>
              </StyleGridItem>
            </StyleGrid>
          )}

          {showServiceName && (
            <FormSelect
              col={4}
              disable={!canEdit}
              title="サービス名称"
              value={defaultValue.serContent}
              name="serContent"
              changeValue={onChangeValue}
              data={serContentData}
            />
          )}
          <FormSelect
            col={4}
            disable={!canEdit}
            title="サービス内容"
            value={serviceDetail}
            onChange={setServiceDetail}
            data={serviceDetailData}
            name="serviceDetail"
          />

          <Form
            form={form}
            name="form"
            onFinish={handleSubmit}
            scrollToFirstError={{ block: "center" }}
            onFinishFailed={(errorInfo: any) => {
              setErrorToScroll(errorInfo.errorFields[0].name[0]);
              handelScrollToError(errorInfo.errorFields[0].name[0]);

              validate();
            }}
          >
            <div id="staff" className="scroll-mt-12">
              <StyledFormItem
                label=""
                name="staff"
                rules={[
                  {
                    required: true,
                    message: "選択してください",
                  },
                ]}
              >
                <FormSelect
                  col={4}
                  disable={canEditStaff}
                  title="担当スタッフ"
                  name="staff"
                  holder={"選択してください"}
                  changeValue={onChangeValue}
                  data={staffInCharge}
                />
              </StyledFormItem>
            </div>
          </Form>
        </div>

        <div className="w-full md:w-1/2 pl-3"></div>

        <StyleTitle className="w-full">■事前チェック</StyleTitle>
        <div className="w-full md:w-3/5">
          <RadioForm
            col={4}
            disable={!canEdit}
            title="顔色"
            rdTit1="良"
            rdTit2="不良"
            rdVal1="good"
            rdVal2="bad"
            value={complex}
            setValue={setComplex}
            error={!canEdit ? false : !complex && errorVal.errComplex}
            id="errComplex"
          />

          <RadioForm
            id="errSweat"
            col={4}
            disable={!canEdit}
            title="発汗"
            rdTit1="有"
            rdTit2="無"
            rdVal1="yes"
            rdVal2="no"
            value={sweat}
            setValue={setSweat}
            error={!canEdit ? false : !sweat && errorVal.errSweat}
          />
          <StyleGrid templateColumns="repeat(4, 1fr)" gap={1}>
            <StyleGridItem
              colSpan={1}
              style={{
                paddingRight: "0px",
                display: "flex",
                alignItems: "flex-end",
              }}
            >
              <TextP style={{ whiteSpace: "nowrap" }}>体温</TextP>
            </StyleGridItem>
            <StyleGridItem colSpan={1}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Input
                  disabled={!canEdit}
                  defaultValue={defaultValue.temperature}
                  value={defaultValue.temperature}
                  onChange={(e) => {
                    onChangeValue(e, e.target.name, e.target.value);
                  }}
                  name="temperature"
                  style={{ width: "145px", height: "40px", fontSize: "16px" }}
                />
                <p
                  style={{
                    paddingLeft: "10px",
                  }}
                >
                  ℃
                </p>
              </div>
            </StyleGridItem>
          </StyleGrid>

          <StyleGrid templateColumns="repeat(4, 1fr)" gap={1}>
            <StyleGridItem
              colSpan={1}
              style={{
                paddingRight: "0px",
                display: "flex",
                alignItems: "flex-end",
              }}
            >
              <TextP style={{ whiteSpace: "nowrap" }}>血圧</TextP>
            </StyleGridItem>
            <StyleGridItem colSpan={1}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "145px",
                }}
              >
                <Input
                  disabled={!canEdit}
                  defaultValue={defaultValue.bloodPressure.systolic}
                  value={defaultValue.bloodPressure.systolic}
                  onChange={(e) => {
                    handleChangeBloodPressure(e);
                  }}
                  name="systolic"
                  style={{ width: "60px", height: "40px", fontSize: "16px" }}
                />
                <span
                  style={{
                    margin: "0 5px",
                    fontSize: "20px",
                    lineHeight: "22px",
                  }}
                >
                  /
                </span>
                <Input
                  disabled={!canEdit}
                  defaultValue={defaultValue.bloodPressure.diastolic}
                  value={defaultValue.bloodPressure.diastolic}
                  onChange={(e) => {
                    handleChangeBloodPressure(e);
                  }}
                  name="diastolic"
                  style={{ width: "60px", height: "40px", fontSize: "16px" }}
                />
              </div>
            </StyleGridItem>
          </StyleGrid>

          <CheckboxForm
            col={4}
            disable={!canEdit}
            title="環境整備"
            content="対応"
            value={checkValue.enviArr}
            changeValue={onChangeCheckValue}
            name="enviArr"
          />
          {/* Consultation assistance */}
          <CheckboxForm
            col={4}
            disable={!canEdit}
            title="相談援助"
            content="対応"
            value={checkValue.consultAssist}
            changeValue={onChangeCheckValue}
            name="consultAssist"
          />
          {/* record */}
          <CheckboxForm
            col={4}
            disable={!canEdit}
            title="記録"
            content="対応"
            value={checkValue.record}
            changeValue={onChangeCheckValue}
            name="record"
          />
        </div>
        <div className="w-full md:w-1/2 pl-3"></div>
        <div className="w-full md:w-1/2 pl-3" />

        <StyleTitle className="w-full">■排泄</StyleTitle>
        <div className="w-full md:w-3/5">
          {/* Toilet Assistant */}
          <CheckboxForm
            col={4}
            disable={!canEdit}
            title="トイレ介助"
            content="対応"
            value={checkValue.toilet}
            changeValue={onChangeCheckValue}
            name="toilet"
          />

          {/* Diaper check/change */}
          <CheckboxForm
            col={4}
            disable={!canEdit}
            title="オムツ確認・交換"
            content="対応"
            value={checkValue.diaper}
            changeValue={onChangeCheckValue}
            name="diaper"
          />

          {/* Pad confirmation/replacement */}
          <CheckboxForm
            col={4}
            disable={!canEdit}
            title="パット確認・交換"
            content="対応"
            value={checkValue.pad}
            changeValue={onChangeCheckValue}
            name="pad"
          />

          {/* Urination */}
          <FormSelect
            col={4}
            disable={!canEdit}
            title="排尿"
            value={defaultValue.urinate}
            name="urinate"
            changeValue={onChangeValue}
            data={dataNum}
            className="w-[193px]"
            unit={<TextP>回</TextP>}
          />

          {/* Defecation */}
          <FormSelect
            col={4}
            disable={!canEdit}
            title="排便"
            value={defaultValue.defecation}
            name="defecation"
            changeValue={onChangeValue}
            data={dataNum}
            className="w-[193px]"
            unit={<TextP>回</TextP>}
          />

          {/* Urinal cleaning */}
          <CheckboxForm
            col={4}
            disable={!canEdit}
            title="尿器洗浄"
            content="対応"
            value={checkValue.urinal}
            changeValue={onChangeCheckValue}
            name="urinal"
          />
        </div>

        <div className="w-full md:w-1/2 pl-3"></div>

        <div className="w-full md:w-1/2 pl-3" />
        <StyleTitle className="w-full">■食事</StyleTitle>
        <div className="w-full md:w-3/5">
          {/* Maintain posture */}
          <CheckboxForm
            col={4}
            disable={!canEdit}
            title="姿勢の確保"
            content="対応"
            value={checkValue.maintain}
            changeValue={onChangeCheckValue}
            name="maintain"
          />

          {/* Hydration */}
          <FormSelect
            col={4}
            disable={!canEdit}
            title="水分補給"
            value={defaultValue.hydration}
            name="hydration"
            changeValue={onChangeValue}
            data={dataNumCC}
            className="w-[193px]"
            unit={<TextP>cc</TextP>}
          />

          {/* Eating assistancel */}
          <FormSelect
            col={4}
            disable={!canEdit}
            title="摂食介助"
            value={defaultValue.eating}
            name="eating"
            changeValue={onChangeValue}
            data={eatingData}
            holder="選択してください"
          />
        </div>
        <StyleTitle className="w-full">■入浴 身体整容</StyleTitle>
        <div className="w-full md:w-3/5">
          {/* cleaning */}
          <FormSelect
            col={4}
            disable={!canEdit}
            title="清拭"
            value={defaultValue.cleaning}
            name="cleaning"
            changeValue={onChangeValue}
            data={eatingData}
            holder="選択してください"
          />

          {/* Full body bath */}
          <FormSelect
            col={4}
            disable={!canEdit}
            title="全身浴"
            value={defaultValue.bodyBath}
            name="bodyBath"
            changeValue={onChangeValue}
            data={bodyBathData}
            holder="選択してください"
          />

          {/* Washing hari */}
          <CheckboxForm
            col={4}
            disable={!canEdit}
            title="洗髪"
            content="対応"
            value={checkValue.hair}
            changeValue={onChangeCheckValue}
            name="hair"
          />
          {/* Bdoy wash */}
          <CheckboxForm
            col={4}
            disable={!canEdit}
            title="洗身"
            content="対応"
            value={checkValue.bodyWash}
            changeValue={onChangeCheckValue}
            name="bodyWash"
          />

          {/* washbasin */}
          <CheckboxForm
            col={4}
            disable={!canEdit}
            title="洗面"
            content="対応"
            value={checkValue.wash}
            changeValue={onChangeCheckValue}
            name="wash"
          />

          {/* Oral care */}
          <CheckboxForm
            col={4}
            disable={!canEdit}
            title="口腔ケア"
            content="対応"
            value={checkValue.oral}
            changeValue={onChangeCheckValue}
            name="oral"
          />

          {/* Dressing assistance */}
          <CheckboxForm
            col={4}
            disable={!canEdit}
            title="更衣介助"
            content="対応"
            value={checkValue.dressing}
            changeValue={onChangeCheckValue}
            name="dressing"
          />
        </div>

        <Text
          style={{
            fontSize: "16px",
            textAlign: "left",
            margin: "10px",
            padding: "10px 0px",
            fontWeight: "bold",
            color: "#000",
          }}
          className="w-full"
        >
          ■移動
        </Text>
        <div className="w-full md:w-3/5">
          {/* position exchange */}
          <CheckboxForm
            col={4}
            disable={!canEdit}
            title="体位交換"
            content="対応"
            value={checkValue.position}
            changeValue={onChangeCheckValue}
            name="position"
          />

          {/* Mobility/transfer assistance */}
          <CheckboxForm
            col={4}
            disable={!canEdit}
            title="移動・移乗介助"
            content="対応"
            value={checkValue.mobility}
            changeValue={onChangeCheckValue}
            name="mobility"
          />
        </div>

        <StyleTitle className="w-full">■その他</StyleTitle>
        <div className="w-full md:w-3/5">
          {/*watch over */}
          <CheckboxForm
            col={4}
            disable={!canEdit}
            title="見守り"
            content="対応"
            value={checkValue.watch}
            changeValue={onChangeCheckValue}
            name="watch"
          />

          <StyleGrid templateColumns="repeat(4, 1fr)" gap={1}>
            <StyleGridItem colSpan={1} style={{ paddingRight: "0px" }}>
              <TextP style={{ whiteSpace: "nowrap" }}>特記事項・申し送り</TextP>
            </StyleGridItem>
            <StyleGridItem colSpan={3}>
              <Textarea
                fontSize="16px"
                disabled={!canEdit}
                value={defaultValue.report}
                onChange={onChangeValue}
                name="report"
                size="md"
                border="1px"
                borderColor="#000"
                borderRadius="0"
                rows={5}
              />
            </StyleGridItem>
          </StyleGrid>
        </div>
      </FlexRow>

      <FlexRow
        style={{ justifyContent: "flex-end" }}
        className={twMerge(
          "fixed bottom-0 right-0 w-full bg-white shadow-md lg:pr-2",
          footerClassName
        )}
      >
        <ButtonSolid
          onClick={handleBack}
          m={3}
          height="32px"
          className="!bg-grey-secondary !min-w-[155px]"
        >
          キャンセル
        </ButtonSolid>
        {isShowEditButton && (
          <ButtonSolid
            onClick={() => {
              canEdit ? form.submit() : setCanEdit?.(true);
            }}
            height="32px"
            className="!min-w-[155px]"
            m={3}
          >
            {canEdit ? "保存" : "編集"}
          </ButtonSolid>
        )}
      </FlexRow>
    </div>
  );
};

const TextP = styled("p")({
  fontSize: "16px",
  color: "#555555",
});

export default DailyForm;

const StyleTimePicker = styled(TimePicker)({
  borderRadius: "0",
  fontWeight: "600",
  color: "var(--primary-text-dark)",
  border: "1px solid black",
});

const StyledFormItem = styled(Form.Item)`
  .ant-form-item-explain-error {
    margin-left: 27%;
  }
`;
