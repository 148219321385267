import { Button, Form, Checkbox } from "antd";
import type { ColumnsType } from "antd/es/table";
import TableAdmin from "component/admin/table";
import { ButtonSolid } from "component/button";
import {  useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import Styles from "./styled";
import _, { forEach, get, omit } from "lodash";
import { toast } from "react-toastify";
import { useWatch } from "antd/es/form/Form";
import useService from "hook/useService";
import axiosClient from "api/axiosClient";
import { TypeRecipient, TypeResListRecipient } from "../../_type";
import { useNavigate } from "react-router-dom";
import ROUTES from "constant/routes";
import ModalConfirmDelete from "component/ModalConfirmDelete";

const RecipientCertification = () => {
  const { id } = useParams();
  const [form] = Form.useForm();
  const page = useWatch("page", form);
  const per = useWatch("per", form);
  const navigate = useNavigate();
  const [dataSource, setDataSource] = useState<TypeRecipient[]>([]);
  const [idsDelete, setIdsDelete] = useState<{ [key: string]: boolean }>({});
  const [isOpenModalDelete, setIsOpenModalDelete] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [total, setTotal] = useState(0)

  const handleCheckDelete = (isDelete: boolean, id: number) => {
    const newIdsDelete = { ...idsDelete };
    if (newIdsDelete.hasOwnProperty(id) && !isDelete) {
      setIdsDelete(omit(newIdsDelete, id));
      return;
    }
    newIdsDelete[id] = isDelete;
    setIdsDelete(newIdsDelete);
  };
  const location = useLocation();

  // Function to update specific search params
  const updateSearchParams = (field: string, value: string) => {
    const searchParams = new URLSearchParams(location.search);
    // Update or set the field param
    searchParams.set(field, value);

    // Keep existing params and update the URL
    navigate(`${location.pathname}?${searchParams.toString()}`, {
      replace: false,
    });
  };
  
  const handleSetData = (data : TypeResListRecipient) => {
    console.log(data)
    const newTotal = get(data,'total_items', 0)
    if (data.recipients) setDataSource(data.recipients);
    setIdsDelete({})
    setTotal(newTotal)
  } 

  const handleCheckAllDelete = (checked: boolean) => {
    const newIdsDelete: { [key: string]: boolean } = {};
    if (checked) {
      forEach(dataSource, (item) => {
        newIdsDelete[item.id] = true;
      });
    }
    setIdsDelete(newIdsDelete);
  };

  const columns: ColumnsType<TypeRecipient> = [
    {
      align: "center",
      title: (
        <div>
          <Checkbox
            checked={
              Object.keys(idsDelete).length === dataSource.length &&
              !!dataSource.length
            }
            onChange={(e) => handleCheckAllDelete(e.target.checked)}
          />
          <span className="ml-2">選択</span>
        </div>
      ),
      width: 94,
      onHeaderCell: (column) => {
        return {
          style: { backgroundColor: "#red", fontWeight: "bold" }, // Custom styles
          onClick: () => {},
        };
      },
      render: (value, record) => (
        <Checkbox
          checked={idsDelete[value.id]}
          onChange={(e) => {
            handleCheckDelete(e.target.checked, record.id);
          }}
        />
      ),
    },
    {
      align: "center",
      title: "サービス内容",
      width: 107,
      dataIndex: "service_content",
      render: (value) => (
        <>
          {_.truncate(value, {
            length:
              value && value.length > 20 && value.length < 23
                ? value.length + 3
                : 23,
            // omission:
          })}
        </>
      ),
      // sorter: true,
    },
    {
      align: "center",
      title: "契約支給量",
      dataIndex: "contract_allocation_amount",
      width: 101,
      render: (value) => (
        <>
          {_.truncate(value, {
            length:
              value && value.length > 20 && value.length < 23
                ? value.length + 3
                : 23,
            // omission:
          })}
        </>
      ),
      // sorter: true,
    },
    {
      align: "center",
      title: "契約日",
      dataIndex: "contract_date",
      width: 67,
      render: (value) => (
        <>
          {_.truncate(value, {
            length:
              value && value.length > 20 && value.length < 23
                ? value.length + 3
                : 23,
            // omission:
          })}
        </>
      ),
      // sorter: true,
    },
    {
      align: "center",
      title: "サービス提供終了日",
      width: 147,
      dataIndex: "service_end_date",
      render: (value) => (
        <>
          {_.truncate(value, {
            length:
              value && value.length > 20 && value.length < 23
                ? value.length + 3
                : 23,
            // omission:
          })}
        </>
      ),
      // sorter: true,
    },
    {
      align: "center",
      title: "適用開始年月日",
      width: 129,
      dataIndex: "applicable_start_date",
      render: (value) => (
        <>
          {_.truncate(value, {
            length:
              value && value.length > 20 && value.length < 23
                ? value.length + 3
                : 23,
            // omission:
          })}
        </>
      ),
      // sorter: true,
    },
    {
      align: "center",
      title: "利用者負担割合(原則)",
      width: 158,
      dataIndex: "user_burden_percentage",
      render: (value) => (
        <>
          {_.truncate(value, {
            length:
              value && value.length > 20 && value.length < 23
                ? value.length + 3
                : 23,
            // omission:
          })}
        </>
      ),
      // sorter: true,
    },
    {
      align: "center",
      title: "負担上限月額",
      width: 105,
      dataIndex: "monthly_cost_limit",
      render: (value) => (
        <>
          {_.truncate(value, {
            length:
              value && value.length > 20 && value.length < 23
                ? value.length + 3
                : 23,
            // omission:
          })}
        </>
      ),
      // sorter: true,
    },
    {
      align: "center",
      title: "上限管理事務所",
      width: 124,
      dataIndex: "special_notes",
      render: (value) => (
        <>
          {_.truncate(value, {
            length:
              value && value.length > 20 && value.length < 23
                ? value.length + 3
                : 23,
            // omission:
          })}
        </>
      ),
    },
    {
      align: "center",
      title: "操作",
      dataIndex: "action",
      width: 62,
      render: (_, record) => (
        <div className="wrapper-action justify-center">
          <ButtonSolid
            width={46}
            onClick={() => updateSearchParams("recipient", record.id + "")}
          >
            詳細
          </ButtonSolid>
        </div>
      ),
    },
  ];
  const service = useService();
  const fetchData = async () => {
    setIdsDelete({});
    try {
      const filter = form.getFieldsValue();
      const res = await axiosClient({
        method: "get",
        url: service.RECIPIENTS,
        params: filter,
      });
      const data = res.data as TypeResListRecipient
      handleSetData(data)
    } catch (error) {
      toast.error("Error");
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const handleOkDelete = async () => {
    setIsDeleting(true)
    const idsArrDelete = Object.keys(idsDelete)
    try {
      const res = await axiosClient({
        method: "post",
        url: `${service.RECIPIENTS}/delete-multiple`,
        data: {ids: idsArrDelete}
      });
      const data = res.data as TypeResListRecipient
      handleSetData(data)
      toast.success("success")
      setIsOpenModalDelete(false)
    }
    catch(e) {
        console.log(e)
    } finally {
      setIsDeleting(false)
    }
  }

  return (
    <>
      <Styles>
        <div className="text-end">
          <ButtonSolid
            isDisabled={!Object.keys(idsDelete).length}
            className="mr-6 !bg-[#D83535]"
            onClick={() => setIsOpenModalDelete(true)}
          >
            削除
          </ButtonSolid>
          <ButtonSolid
            width={106}
            onClick={() =>
              navigate(
                `/${ROUTES.ADMIN}/${ROUTES.CUSTOMER}/${ROUTES.CREATE}?defaultTab=8`
              )
            }
          >
            新規登録
          </ButtonSolid>
        </div>
        <div className="overflow-x-auto">
          <Form form={form} onFinish={() => fetchData()}>
            <TableAdmin
              form={form}
              data={dataSource}
              columns={columns}
              loading={false}
              total={total}
              pagination={{
                position: ["bottomRight"],
                showSizeChanger: true,
                showTotal: () => (
                  <div>
                    {(page - 1) * per + 1} ~
                    {page * per > total ? total : page * per}
                    件(全{total}件中)
                  </div>
                ),
                showQuickJumper: true,
                total,
                pageSize: per,
                current: page,
                pageSizeOptions: [10, 20, 50, 100],
              }}
            />
          </Form>
        </div>
      </Styles>

      <ModalConfirmDelete
        open={isOpenModalDelete}
        onCancel={() => setIsOpenModalDelete(false)}
        onOk={handleOkDelete}
        okButtonProps={{loading: isDeleting}}
      />
    </>
  );
};

export default RecipientCertification;
