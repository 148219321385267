import Form from './components/Form'           // Form RecipientCertification
import TableList from './components/TableList' // Table list RecipientCertification
import { useParams, useSearchParams } from 'react-router-dom';

const RecipientCertification = () => {
  const {id} = useParams()
  const [searchParams] = useSearchParams()

  const idRecipient = searchParams.get("recipient");
  return  !idRecipient && id? <TableList/> : <Form/>
}
export default RecipientCertification